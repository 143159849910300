// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import BrandsList from '~/packages/spa-ec/src/components/BrandsList/BrandsList.theme-dammsugarpasar.js';
import Bubble from '~/packages/spa-ec/src/displayComponents/Bubble/Bubble.theme-dammsugarpasar.js';
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-dammsugarpasar.js';
import CartSummary from '~/packages/spa-ec/src/components/QliroCheckout/CartSummary.theme-dammsugarpasar.js';
import CartTable from '~/packages/spa-ec/src/displayComponents/CartTable/CartTable.theme-dammsugarpasar.js';
import CategoryListing from '~/packages/spa-ec/src/components/CategoryListing/CategoryListing.theme-dammsugarpasar.js';
import Cheapest from '~/packages/spa-ec/src/displayComponents/PrisjaktCheapest/Cheapest.theme-dammsugarpasar.js';
import ComponentsContainer from '~/packages/spa-ec/src/displayComponents/Cms/ComponentsContainer.theme-dammsugarpasar.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-dammsugarpasar.js';
import ContentPage from '~/packages/spa-ec/src/routes/ContentPage/ContentPage.theme-dammsugarpasar.js';
import Footer from '~/packages/spa-ec/src/components/Footer/Footer.theme-dammsugarpasar.js';
import GDPRPopupMod from '~/packages/spa-ec/src/components/GDPRPopup/GDPRPopupMod.theme-dammsugarpasar.js';
import Images from '~/packages/spa-ec/src/components/ProductDetails/Images/themes/Images.theme-dammsugarpasar.js';
import Login from '~/packages/spa-ec/src/components/Login/Login.theme-dammsugarpasar.js';
import ModelFinder from '~/packages/spa-ec/src/components/ModelFinder/ModelFinder.theme-dammsugarpasar.js';
import ModelShelf from '~/packages/spa-ec/src/components/ModelShelf/ModelShelf.theme-dammsugarpasar.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-dammsugarpasar.js';
import OneBuyPanel from '~/packages/spa-ec/src/components/ProductDetails/OneBuyPanel/themes/OneBuyPanel.theme-dammsugarpasar.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-dammsugarpasar.js';
import OrderConfirmationPage from '~/packages/spa-ec/src/routes/OrderConfirmationPage/OrderConfirmationPage.theme-dammsugarpasar.js';
import OrderHistory from '~/packages/spa-ec/src/components/OrderHistory/OrderHistory.theme-dammsugarpasar.js';
import PageTitle from '~/packages/spa-ec/src/components/StaticPageComponents/PageTitle.theme-dammsugarpasar.js';
import PasswordRequest from '~/packages/spa-ec/src/components/PasswordRequest/PasswordRequest.theme-dammsugarpasar.js';
import ProductDetails from '~/packages/spa-ec/src/components/ProductDetails/themes/ProductDetails.theme-dammsugarpasar.js';
import ProductGrid from '~/packages/spa-ec/src/displayComponents/ProductGrid/ProductGrid.theme-dammsugarpasar.js';
import ProductPromotionMod from '~/packages/spa-ec/src/components/ProductPromotion/ProductPromotionMod.theme-dammsugarpasar.js';
import ProductRowMod from '~/packages/spa-ec/src/components/ModelShelf/ProductRowMod.theme-dammsugarpasar.js';
import ProductStockStatusMonitor from '~/packages/spa-ec/src/components/ProductDetails/ProductStockStatusMonitor/themes/ProductStockStatusMonitor.theme-dammsugarpasar.js';
import ProductUpsellCarouselMod from '~/packages/spa-ec/src/components/ProductUpsell/ProductUpsellCarouselMod.theme-dammsugarpasar.js';
import QliroCheckout from '~/packages/spa-ec/src/components/QliroCheckout/QliroCheckout.theme-dammsugarpasar.js';
import Radio from '~/packages/ui-elem/src/Radio.theme-dammsugarpasar.js';
import RestoreCartPanel from '~/packages/spa-ec/src/components/RestoreCart/RestoreCartPanel.theme-dammsugarpasar.js';
import Result from '~/packages/spa-ec/src/components/Search/Result.theme-dammsugarpasar.js';
import SearchList from '~/packages/spa-ec/src/components/Search/SearchList.theme-dammsugarpasar.js';
import ShelfSection from '~/packages/spa-ec/src/components/ModelShelf/ShelfSection.theme-dammsugarpasar.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-dammsugarpasar.js';
import StockStatus from '~/packages/spa-ec/src/components/StockStatus/StockStatus.theme-dammsugarpasar.js';
import SummaryTable from '~/packages/spa-ec/src/displayComponents/CartTable/SummaryTable.theme-dammsugarpasar.js';
import USPItem from '~/packages/spa-ec/src/displayComponents/Cms/USPItem.theme-dammsugarpasar.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-dammsugarpasar.js';

const overrides = {
  BrandsList,
  Bubble,
  CartMod,
  CartSummary,
  CartTable,
  CategoryListing,
  Cheapest,
  ComponentsContainer,
  ContactUsPage,
  ContentPage,
  Footer,
  GDPRPopupMod,
  Images,
  Login,
  ModelFinder,
  ModelShelf,
  MyPages,
  OneBuyPanel,
  OrderConfirmation,
  OrderConfirmationPage,
  OrderHistory,
  PageTitle,
  PasswordRequest,
  ProductDetails,
  ProductGrid,
  ProductPromotionMod,
  ProductRowMod,
  ProductStockStatusMonitor,
  ProductUpsellCarouselMod,
  QliroCheckout,
  Radio,
  RestoreCartPanel,
  Result,
  SearchList,
  ShelfSection,
  SiteLogo,
  StockStatus,
  SummaryTable,
  USPItem,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
