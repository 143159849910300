import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ConsumerListName, } from '@spa-ec/components/PersonalizedCockpit/ConsumerList/ConsumerList.theme';
import { NAME as DesktopHeaderName, HeaderComponents } from '@spa-ec/components/Header/DesktopHeader.theme';
const headerTheme = {
    showCountrySelector: false,
};
const consumerListTheme = {
    alternateDustbag: false,
};
const desktopHeaderTheme = {
    headerComponents: [
        HeaderComponents.SITE_LOGO,
        HeaderComponents.PROMOTIONS,
        HeaderComponents.SEARCH,
        HeaderComponents.MY_ACCOUNT_AND_CART,
    ],
};
export default {
    [HeaderName]: headerTheme,
    [DesktopHeaderName]: desktopHeaderTheme,
    [ConsumerListName]: consumerListTheme,
};
